/**
 * CKEditor
 */
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'

/**
 * Icons
 */
import collapse from './icons/collapse.svg'
import expand from './icons/expand.svg'

export default class Collapse extends Plugin {
  init() {
    const editor = this.editor
    let collapsed = false

    editor.ui.componentFactory.add('collapse', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        label: 'Minimize input',
        icon: collapse,
        tooltip: true,
      })

      view.on('execute', () => {
        collapsed = !collapsed

        view.set({
          label: collapsed ? 'Expand input' : 'Minimize input',
          icon: collapsed ? expand : collapse,
          isOn: collapsed,
          tooltip: true,
        })

        this.triggerEvent(collapsed)
      })

      return view
    })
  }

  triggerEvent(collapsed) {
    const event = new CustomEvent('editor-collapse', { detail: collapsed })
    document.dispatchEvent(event)
  }
}
