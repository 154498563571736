/**
 * CKEditor
 */
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'

/**
 * Icons
 */
import maximise from './icons/maximise.svg'
import minimise from './icons/minimise.svg'

export default class Fullscreen extends Plugin {
  init() {
    const editor = this.editor
    let fullscreen = false

    editor.ui.componentFactory.add('fullscreen', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        label: 'Enter fullscreen',
        icon: maximise,
        tooltip: true,
      })

      view.on('execute', () => {
        fullscreen = !fullscreen

        document.body.classList.toggle('editor-fullscreen')

        view.set({
          label: fullscreen ? 'Exit fullscreen' : 'Enter fullscreen',
          icon: fullscreen ? minimise : maximise,
          isOn: fullscreen,
          tooltip: true,
        })

        this.triggerEvent(fullscreen)
      })

      return view
    })
  }

  triggerEvent(fullscreen) {
    const event = new CustomEvent('editor-fullscreen', { detail: fullscreen })
    document.dispatchEvent(event)
  }
}
