/**
 * CKEditor
 */
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'

/**
 * Icons
 */
import trash from './icons/trash.svg'

export default class DeleteTable extends Plugin {
  init() {
    const editor = this.editor

    editor.ui.componentFactory.add('deleteTable', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        class: 'delete-table',
        label: 'Delete table',
        icon: trash,
        tooltip: true,
      })

      view.on('execute', () => {
        const tableUtils = this.editor.plugins.get('TableUtils')
        const model = this.editor.model
        const selectedCells = tableUtils.getSelectionAffectedTableCells(model.document.selection)
        const table = selectedCells[0].findAncestor('table')

        model.change((writer) => {
          writer.remove(table)
        })
      })

      return view
    })
  }
}
